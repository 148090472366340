import {LoginSubmitButton,
  isWhitelabeled,
  SsoLogin,
  LoginActions,
  EmployeeZendeskRedirectLogin} from '/src/com/yext/platform/users/js/newloginhelpers/logincomponents';
import NewLoginTemplate from '/src/com/yext/platform/users/js/newloginhelpers/newlogintemplate';
import {registerComponentForJsonDataWithUIKit} from '/ui/lib/easyuikit';
import {AJAX_HEADER, checkResponseStatus} from '/ui/lib/fetch';
import {yext} from '/ui/lib/msg';

const {
  FALEnvelope,
  Form,
  FormControl,
  FormErrorMessage,
  FALCircleExclamation,
  VStack,
} = require('@yext/mana-ui');

export function NewGlobalLogin({username, b, c, whitelabelInfo, isMobileRedirect, rememberMe, isAllState}) {
  // If we find users in multiple partitions, we need the user to clarify their business ID.
  const promptBusinessId = React.useRef(false);
  // Whether it is the SSO section
  const [isSSOSection, setIsSSOSection] = React.useState(false);

  return (
    <NewLoginTemplate
      title={isWhitelabeled(whitelabelInfo)
        ? yext.msg('Welcome to {0}', whitelabelInfo.name)
        : yext.msg('Welcome to Yext')}
      subtitle={yext.msg('Please sign in to continue.')}
      cardContent={
        <CardContent
          whitelabelInfo={whitelabelInfo}
          initialUsername={username}
          b={b}
          c={c}
          promptBusinessId={promptBusinessId}
          isSSOSection={isSSOSection}
          setIsSSOSection={setIsSSOSection}
          initialRememberMe={rememberMe}
        />}
      whitelabelInfo={whitelabelInfo}
      pageType={'login'}
      isMobileRedirect={isMobileRedirect}
      showBackToUsername={promptBusinessId.current || isSSOSection}
      isAllState={isAllState}
    />
  );
}

function CardContent({initialUsername, b, c, whitelabelInfo, promptBusinessId, isSSOSection, setIsSSOSection, initialRememberMe}) {
  // Error message to be displayed (TODO: display error messages)
  const [error, setError] = React.useState('');
  // Whether to disable the submit button or not
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = React.useState(false);

  /** Make API call to get a list of login types for a given username. */
  const getUserPartition = formData => {
    const {username, accountId, rememberMe} = formData;

    if (username != null) {
      if (!username || username === '' || username.trim() === '') {
        setError(yext.msg('Please enter a username.'));
        setIsSubmitBtnDisabled(false);
        return;
      }
    }

    const url = new URL('/users/login/getPartition', window.location.origin);
    url.searchParams.set('username', username || initialUsername);
    url.searchParams.set('c', c || '');
    url.searchParams.set('b', accountId || b);
    url.searchParams.set('rememberMe', rememberMe ?? false);

    fetch(url.toString(), {
      method: 'GET',
      headers: AJAX_HEADER,
    })
      .then(checkResponseStatus)
      .then(response => response.json())
      .then(data => {
        handleResponse(data);
      })
      .finally(() => setIsSubmitBtnDisabled(false));
  };

  const handleResponse = response => {
    switch (response['action']) {
      case 'REDIRECT':
        // If the controller definitively finds the partition, redirect there.
        window.location = response['url'];
        break;
      case 'MULTIPLE_PARTITIONS':
        // If multiple partitions are possible, ask for business ID.
        promptBusinessId.current = true;
        setIsSubmitBtnDisabled(false);
        break;
      case 'NULL_OR_EMPTY_USERNAME':
        // If a null or empty username is submitted, set error.
        setError(yext.msg('Please enter a username.'));
        setIsSubmitBtnDisabled(false);
    }
  };

  const onSubmit = formData => {
    // deactivate the submit button when the user submits the username
    // while waiting for a response
    setIsSubmitBtnDisabled(true);
    getUserPartition(formData);
  };

  return (
    <FormControl isInvalid={error?.trim()}>
      <Form onSubmit={onSubmit} defaultValues={{rememberMe: initialRememberMe ?? false}}>
        {({Field, DisplayIf}) => (
          <>
            <VStack
              spacing={5}
              align={'stretch'}
            >
              <DisplayIf
                name={'usernameField'}
                condition={() => !promptBusinessId.current}
              >
                <Field
                  name={'username'}
                  type={'text'}
                  variant={'gray'}
                  label={yext.msg('Username')}
                  placeholder={'john@abccompany.com'}
                  isRequired={true}
                  icon={<FALEnvelope/>}
                  defaultValue={initialUsername}
                />
                <FormErrorMessage>
                  <FALCircleExclamation me={1.5} />
                  {error}
                </FormErrorMessage>
              </DisplayIf>
              <DisplayIf
                name={'accountIdField'}
                condition={() => !!promptBusinessId.current}
              >
                <Field
                  name={'accountId'}
                  type={'text'}
                  variant={'gray'}
                  label={yext.msg('Account ID')}
                  isRequired={true}
                  defaultValue={b}
                />
              </DisplayIf>
              <VStack
                spacing={4}
                align={'stretch'}
              >
                <LoginSubmitButton whitelabelInfo={whitelabelInfo} isDisabled={isSubmitBtnDisabled}>
                  {yext.msg('Next')}
                </LoginSubmitButton>
                <DisplayIf name={'extraActions'} condition={() => !isSSOSection}>
                  <>
                    <LoginActions
                      initialRememberMe={initialRememberMe}
                    />
                    <EmployeeZendeskRedirectLogin redirectUrl={c} />
                    <SsoLogin
                      onClick={() => setIsSSOSection(true)}
                      shouldShowSsoLogin={true}
                    />
                  </>
                </DisplayIf>
              </VStack>
            </VStack>
          </>
        )}
      </Form>
    </FormControl>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  registerComponentForJsonDataWithUIKit('NewGlobalLogin', NewGlobalLogin);
});
